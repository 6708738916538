// Modules
import React, { useState } from "react";
// Styles
import "./HeroSquareImage.css";

function HeroSquareImage({ darkMode, permalink, image_url, collectionName, itemName, token, windowWidth }) {
  const [hoverImage, setHoverImage] = useState(false);

  let reduceImages = false;
  if (windowWidth > 0 && windowWidth < 1024) {
    reduceImages = true;
  } else {
    reduceImages = false;
  }

  return (
    <div
      className={`heroSquareImage-mainImageContainer ${
        reduceImages ? "heroSquareImage-mainImageContainerReduced" : ""
      }`}
      onMouseEnter={() => setHoverImage(true)}
      onMouseLeave={() => setHoverImage(false)}
    >
      <img
        alt=""
        className={`heroSquareImage-mainImage ${darkMode ? "heroSquareImage-mainImageDark" : ""} ${
          reduceImages ? "heroSquareImage-mainImageReduced" : ""
        }`}
        onClick={() => window.open(permalink, "_blank")}
        src={image_url}
      />
      {hoverImage && (
        <div className="heroSquareImage-imageDescription">
          <div className="heroSquareImage-title">{collectionName}</div>
          <div className="heroSquareImage-description">{itemName || `${token ? `#${token}` : ""}`}</div>
        </div>
      )}
    </div>
  );
}

export default HeroSquareImage;
