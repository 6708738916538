// Modules
import React from "react";
// Icons
import { BsMoonFill, BsTwitter, BsFillSunFill } from "react-icons/bs";
// Styles
import "./Header.css";

function Header({ darkMode, setDarkMode }) {
  const lightLogo = "./logo_snackable_light.svg";
  const darkLogo = "./logo_snackable_dark.svg";
  const logo = darkMode ? lightLogo : darkLogo;
  const iconStyle = darkMode ? { color: "#FFFFFF", height: "14px", width: "14px" } : { height: "14px", width: "14px" };

  return (
    <div className="appHeader-header">
      <div className="appHeader-logo">
        <img alt="" src={logo} />
      </div>
      <div className="appHeader-iconsContainer">
        <div
          className={`appHeader-iconButton ${darkMode ? "appHeader-iconButtonDark" : ""}`}
          onClick={() => window.open("https://twitter.com/SnackableApp", "_blank")}
        >
          <BsTwitter style={iconStyle} />
        </div>
        <div
          className={`appHeader-iconButton ${darkMode ? "appHeader-iconButtonDark" : ""}`}
          onClick={() => setDarkMode(!darkMode)}
        >
          {darkMode ? <BsFillSunFill style={iconStyle} /> : <BsMoonFill style={iconStyle} />}
        </div>
      </div>
    </div>
  );
}

export default Header;
