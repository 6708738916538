// Modules
import React from "react";
// Styles
import "./SkeletonImage.css";

function SkeletonImage({ mainImage, windowWidth }) {
  let reduceImages = false;
  if (windowWidth > 0 && windowWidth < 1024) {
    reduceImages = true;
  } else {
    reduceImages = false;
  }

  return (
    <div
      className={`skeleton-box ${
        mainImage
          ? `skeleton-mainImageContainer ${reduceImages ? "skeleton-mainImageContainerReduced" : ""}`
          : `skeleton-imageContainer ${reduceImages ? "skeleton-imageContainerReduced" : ""}`
      } `}
    />
  );
}

export default SkeletonImage;
