// Modules
import React, { useState } from "react";
// Styles
import "./SquareImage.css";

function SquareImage({ darkMode, permalink, image_url, collectionName, itemName, token, windowWidth }) {
  const [hoverImage, setHoverImage] = useState(false);

  let reduceImages = false;
  if (windowWidth > 0 && windowWidth < 1024) {
    reduceImages = true;
  } else {
    reduceImages = false;
  }

  return (
    <div
      className={`squareImage-imageContainer ${reduceImages ? "squareImage-imageContainerReduced" : ""}`}
      onMouseEnter={() => setHoverImage(true)}
      onMouseLeave={() => setHoverImage(false)}
    >
      <img
        alt=""
        className={`squareImage-image ${darkMode ? "squareImage-imageDark" : ""} ${
          reduceImages ? "squareImage-imageReduced" : ""
        }`}
        onClick={() => window.open(permalink, "_blank")}
        src={image_url}
      />
      {hoverImage && (
        <div className="squareImage-imageDescription">
          <div className={`squareImage-title ${reduceImages ? "squareImage-titleReduced" : ""}`}>{collectionName}</div>
          <div className={`squareImage-description ${reduceImages ? "squareImage-descriptionReduced" : ""}`}>
            {itemName || `${token ? `#${token}` : ""}`}
          </div>
        </div>
      )}
    </div>
  );
}

export default SquareImage;
