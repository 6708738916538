// Modules
import React from "react";
// Components
import SkeletonImage from "../SkeletonImage/SkeletonImage";
import SquareImage from "../SquareImage/SquareImage";
// Styles
import "./FeaturedCollection.css";

function FeaturedCollection({
  darkMode,
  featuredCollection,
  featuredCollectionAssets,
  imagesContainerStyle,
  imagesWidth,
  windowWidth
}) {
  return (
    <div className="featuredCollection-container" style={{ width: imagesWidth }}>
      <div className="featuredCollection-titleContainer">
        <span className={`featuredCollection-title ${darkMode ? "featuredCollection-titleWhite" : ""}`}>
          Featured collection
        </span>
        {imagesWidth !== 336 && (
          <span
            className={`${darkMode ? "featuredCollection-viewAllDark" : "featuredCollection-viewAll"}`}
            onClick={() => window.open(`https://opensea.io/collection/${featuredCollection}`, "_blank")}
          >
            View All
          </span>
        )}
      </div>
      <div className={`${imagesContainerStyle}`}>
        {featuredCollectionAssets.map((nft, index) => {
          if (nft) {
            return (
              <SquareImage
                collectionName={nft.collection.name}
                darkMode={darkMode}
                image_url={nft.image_url}
                itemName={nft.name}
                key={index}
                permalink={nft.permalink}
                token={nft.token_id}
                windowWidth={windowWidth}
              />
            );
          }

          return <SkeletonImage key={index} windowWidth={windowWidth} />;
        })}
        {imagesWidth === 336 && (
          <div
            className={`featuredCollection-seeAll ${darkMode ? "featuredCollection-seeAllDark" : ""}`}
            onClick={() => window.open(`https://opensea.io/collection/${featuredCollection}`, "_blank")}
          >
            <span className={`featuredCollection-seeAllLabel ${darkMode ? "featuredCollection-seeAllLabelDark" : ""}`}>
              View All
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default FeaturedCollection;
