export const firebaseConfig = {
  apiKey: "AIzaSyCnBjkiDgX_F-WJbOVzq9m9tslzfo0BclY",
  authDomain: "snackable-nft.firebaseapp.com",
  databaseURL: "https://snackable-nft-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "snackable-nft",
  storageBucket: "snackable-nft.appspot.com",
  messagingSenderId: "664025550123",
  appId: "1:664025550123:web:7f78fe3b070c6c2d1f096e",
  measurementId: "G-W0P7SQSVMV"
};
