// Modules
import React from "react";
// Styles
import "./Footer.css";

function Footer({ darkMode, imagesWidth }) {
  return (
    <span className={`footer-description ${darkMode ? "footer-descriptionWhite" : ""}`} style={{ width: imagesWidth }}>
      © 2022 Snackable. All rights reserved.
    </span>
  );
}

export default Footer;
