// Modules
import React from "react";
// Styles
import "./ChromeHeader.css";

function ChromeHeader({ darkMode }) {
  return (
    <div className="chromeHeader-innerHeader">
      <div className={`chromeHeader-extensionTitle ${darkMode ? "chromeHeader-extensionTitleWhite" : ""}`}>
        Snackable is a new-tab browser plugin that instantly delivers curated NFTs art.
      </div>
      <div className="chromeHeader-extensionButton">
        <img alt="" src="./chrome.svg" />
        <span
          className="chromeHeader-extensionButtonDescription"
          onClick={() =>
            window.open(
              `https://chrome.google.com/webstore/detail/snackable-nft/oacagejchemehhpkmbdgoehbnaklikge`,
              "_blank"
            )
          }
        >
          Get Snackable For Chrome
        </span>
      </div>
    </div>
  );
}

export default ChromeHeader;
