// Modules
import React, { Fragment, useEffect, useState, useRef } from "react";
import moment from "moment";
// Firebase
import { firebaseConfig } from "./config/firebase";
import { getDatabase, ref, get } from "firebase/database";
import { initializeApp } from "firebase/app";
// Components
import ChromeHeader from "./components/ChromeHeader/ChromeHeader";
import FeaturedCollection from "./components/FeaturedCollection/FeaturedCollection";
import Header from "./components/Header/Header";
import HeroSquareImage from "./components/HeroSquareImage/HeroSquareImage";
import SkeletonImage from "./components/SkeletonImage/SkeletonImage";
import SquareImage from "./components/SquareImage/SquareImage";
import Footer from "./components/Footer/Footer";
// Styles
import "./App.css";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const topNftsCollectionsRef = ref(db, "top-nfts");
const featuredCollectionRef = ref(db, "featured-collection");

function App() {
  const containerRef = useRef(null);

  const [topNftsCollections, setTopNftsCollections] = useState([]);
  const [topNfts, setTopNfts] = useState(Array(12).fill(""));
  const [featuredCollection, setFeaturedCollection] = useState(null);
  const [featuredCollectionAssets, setFeaturedCollectionAssets] = useState(Array(5).fill(""));
  const [darkMode, setDarkMode] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const localStorageNfts = JSON.parse(window.localStorage.getItem("topNfts"));
    const localStorageFeaturedCollection = JSON.parse(window.localStorage.getItem("featuredCollection"));
    const localStorageFeaturedCollectionAssets = JSON.parse(window.localStorage.getItem("featuredCollectionAssets"));

    const today = new Date();
    const currentTimeStamp = moment(today).format();
    const localStorageTimeStamp = JSON.parse(window.localStorage.getItem("timeStamp"));
    const passedByMinutes = moment(currentTimeStamp).diff(moment(localStorageTimeStamp), "minutes");

    let topNftsWithNulls = false;
    if (!!localStorageNfts) {
      topNftsWithNulls = localStorageNfts.includes(null);
    }

    if (!!localStorageNfts && !topNftsWithNulls && passedByMinutes < 60) {
      setTopNfts(localStorageNfts);
    } else {
      get(topNftsCollectionsRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            setTopNftsCollections(snapshot.val());
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    let featuredAssetsWithNulls = false;
    if (!!localStorageFeaturedCollectionAssets) {
      featuredAssetsWithNulls = localStorageFeaturedCollectionAssets.includes(null);
    }

    if (!!localStorageFeaturedCollectionAssets && !featuredAssetsWithNulls && passedByMinutes < 60) {
      setFeaturedCollectionAssets(localStorageFeaturedCollectionAssets);
      setFeaturedCollection(localStorageFeaturedCollection);
    } else {
      get(featuredCollectionRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const featuredCollection = snapshot.val();
            setFeaturedCollection(featuredCollection);
            window.localStorage.setItem("featuredCollection", JSON.stringify(featuredCollection));
            getFeaturedCollection(featuredCollection);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    window.addEventListener("resize", handleResize);
    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleResize() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    if (topNftsCollections.length > 0) {
      getAllNfts(topNftsCollections);
    }
  }, [topNftsCollections]);

  async function getNft(collection) {
    try {
      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "X-API-KEY": "af3e243461a34766a20855fa6918a9a8"
        }
      };
      const response = await fetch(
        `https://api.opensea.io/api/v1/assets?order_by=sale_count&order_direction=desc&offset=0&limit=1&collection=${collection}`,
        options
      );

      if (response.status !== 200) {
        return {};
      }

      return response.json();
    } catch (error) {
      return {};
    }
  }

  async function getAllNfts(topNftsCollections) {
    let topNfts = [];
    for (let index = 0; index < topNftsCollections.length; index++) {
      const collection = topNftsCollections[index];
      const nft = await getNft(collection);
      topNfts.push(nft);
    }

    topNfts = topNfts.map((nft) => {
      if (!nft.assets) {
        return null;
      }

      return nft.assets[0];
    });
    setTopNfts(topNfts);

    const today = new Date();
    const timeStamp = moment(today).format();

    if (topNfts.length > 0) {
      window.localStorage.setItem("topNfts", JSON.stringify(topNfts));
      window.localStorage.setItem("timeStamp", JSON.stringify(timeStamp));
    }
  }

  async function getFeaturedCollection(featuredCollection) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "X-API-KEY": "af3e243461a34766a20855fa6918a9a8"
      }
    };
    await fetch(
      `https://api.opensea.io/api/v1/assets?order_by=sale_count&order_direction=desc&offset=0&limit=5&collection=${featuredCollection}`,
      options
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        const featuredCollectionAssets = jsonResponse.assets.map((nft) => {
          return nft;
        });
        setFeaturedCollectionAssets(featuredCollectionAssets);
        if (featuredCollectionAssets.length > 0) {
          window.localStorage.setItem("featuredCollection", JSON.stringify(featuredCollection));
          window.localStorage.setItem("featuredCollectionAssets", JSON.stringify(featuredCollectionAssets));
        }
      })
      .catch((error) => console.log(error));
  }

  let imagesWidth = 0;
  let imagesContainerStyle = "app-imagesContainer";
  if (windowWidth >= 1024 && windowWidth <= 1280) {
    imagesContainerStyle = "app-imagesContainer1280";
    imagesWidth = 908;
  } else if (windowWidth > 0 && windowWidth < 1024) {
    imagesContainerStyle = "app-imagesContainer1024";
    imagesWidth = 336;
  } else {
    imagesContainerStyle = "app-imagesContainer";
    imagesWidth = 1140;
  }

  return (
    <div className={`app-mainContainer ${darkMode && "app-darkMainContainer"}`}>
      <div className="app-innerContainer" ref={containerRef}>
        <Header darkMode={darkMode} setDarkMode={setDarkMode} />
        <ChromeHeader darkMode={darkMode} />
        <div className="app-titleContainer" style={{ width: imagesWidth }}>
          <span className={`app-title ${darkMode ? "app-titleWhite" : ""}`}>Top collections</span>
          {imagesWidth !== 336 && (
            <span
              className={`${darkMode ? "app-viewAllDark" : "app-viewAll"}`}
              onClick={() => window.open(`https://opensea.io/rankings`, "_blank")}
            >
              View All
            </span>
          )}
        </div>
        <div className={imagesContainerStyle}>
          {topNfts.map((nft, index) => {
            if (nft) {
              return (
                <Fragment key={index}>
                  {index === 0 ? (
                    <HeroSquareImage
                      darkMode={darkMode}
                      permalink={nft.permalink}
                      image_url={nft.image_url}
                      collectionName={nft.collection.name}
                      itemName={nft.name}
                      token={nft.token_id}
                      windowWidth={windowWidth}
                    />
                  ) : (
                    <SquareImage
                      darkMode={darkMode}
                      permalink={nft.permalink}
                      image_url={nft.image_url}
                      collectionName={nft.collection.name}
                      itemName={nft.name}
                      token={nft.token_id}
                      windowWidth={windowWidth}
                    />
                  )}
                </Fragment>
              );
            }

            return <SkeletonImage mainImage={index === 0} key={index} windowWidth={windowWidth} />;
          })}
          {imagesWidth === 336 && (
            <div
              className={`app-seeAll ${darkMode ? "app-seeAllDark" : ""}`}
              onClick={() => window.open("https://opensea.io/rankings", "_blank")}
            >
              <span className={`app-seeAllLabel ${darkMode ? "app-seeAllLabelDark" : ""}`}>View All</span>
            </div>
          )}
        </div>
        <FeaturedCollection
          darkMode={darkMode}
          featuredCollection={featuredCollection}
          featuredCollectionAssets={featuredCollectionAssets}
          imagesContainerStyle={imagesContainerStyle}
          imagesWidth={imagesWidth}
          windowWidth={windowWidth}
        />
        <Footer darkMode={darkMode} imagesWidth={imagesWidth} />
      </div>
    </div>
  );
}

export default App;
